import { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { ToastContainer } from "react-toastify"
import { Switch, Route } from 'react-router-dom'
import auth from './services/authService'

const LoginForm = loadable(() => import('./components/LoginForm'))
const PageNotFound = loadable(() => import('./components/NotFound'))
const ProtectedRoute = loadable(() => import('./components/ProtectedRoute'))
const Admin = loadable(() => import('./components/Admin'))

const BaseRouter = () => {
    const[currentUser, setCurrentUser] = useState({
        data: {},
        isAdmin: false
    })

    useEffect(() => {
        const user = auth.getCurrentLoggedUser()
        const currUser = JSON.parse(user)
        const isAdmin = currUser && currUser?.role?.slug === 'super-admin'
        setCurrentUser({ data: currUser, isAdmin})
    }, [])
    
    return (
        <>
            <ToastContainer 
                autoClose={4000}
                pauseOnVisibilityChange={false}
                draggable={false}
                pauseOnHover={false}
                pauseOnFocusLoss={false}
            />
            <Switch>
                <Route exact path="/" component={LoginForm} />
                <ProtectedRoute path="/admin" 
                    render={props => <Admin {...props} userAuth={currentUser} />}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </>
    );
}
 
export default BaseRouter;