import { BrowserRouter } from 'react-router-dom'
import BaseRouter from './routes'

import './components/icons/iconModule'
import './assets/scss/custom/app/app.scss'

function App(props){
	return (
		<BrowserRouter>
			<BaseRouter {...props} />
		</BrowserRouter>
	);
}

export default App;
