import jwtDecode from 'jwt-decode'
import http from './httpService'

const apiEndpoint = '/login'
const tokenKey = 'token'
const loggedUser = 'userData'

export const login = async values => {
    const response = await http.post(`${apiEndpoint}`, values)
    if(response.data.error) throw new Error(response.data.error.message)
    const { data: { data: { token: jwt, user }}} = response
    localStorage.setItem(tokenKey, jwt)
    localStorage.setItem(loggedUser, JSON.stringify(user))
}

export const loginWithJwt = jwt => {
    localStorage.setItem(tokenKey, jwt);
}

export const logout = () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(loggedUser)
}

export const getCurrentUser = () => {
    try {
        const jwt = localStorage.getItem(tokenKey);  
        if (jwtDecode(jwt).exp < Date.now() / 1000) {
            logout()
        }
        else {
            return jwtDecode(jwt);
        }
    }
    catch (err) {
        return null;
    }
}

export const getCurrentLoggedUser = () => localStorage.getItem(loggedUser);

export const getJwt = () => {
    return localStorage.getItem(tokenKey);
}

http.setJwt(getJwt());

const auth = {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getCurrentLoggedUser,
    getJwt
}

export default auth

